<template>
  <v-card class="rounded" :elevation="5">
    <v-tabs v-model="tab" grow>
      <v-tab
        v-for="plano in planos"
        :key="plano.id"
        :disabled="plano.tickets < $store.state.plano.planoAtual.tickets"
        v-show="
          plano.tickets >= $store.state.plano.planoAtual.tickets && plano.ativo
        "
      >
        <span
          v-if="plano.id === $store.state.usuario.usuario.empresa.plano_id"
          v-text="'Plano Atual'"
        />

        <span v-else>
          {{ plano.descricao }}
        </span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="plano in planos" :key="plano.id">
        <v-card flat>
          <v-card-title class="justify-center text-uppercase">
            {{ plano.complemento }}
          </v-card-title>

          <v-card-text>
            <p class="title text-capitalize">
              <v-icon large color="blue"> mdi-check-circle-outline </v-icon>
              Ticket Disponíveis:
              <span class="font-weight-black">
                {{ plano.tickets }}
              </span>
            </p>

            <p class="title text-capitalize">
              <v-icon large color="blue"> mdi-check-circle-outline </v-icon>
              Valor Mensal:
              <span class="font-weight-black">
                R$ {{ plano.valor_mensal }}
              </span>
            </p>

            <p class="title text-capitalize">
              <v-icon large color="blue"> mdi-check-circle-outline </v-icon>
              Valor Anual:
              <span class="font-weight-black">
                R$ {{ plano.valor_anual }}
              </span>
            </p>

            <p class="title text-capitalize">
              <v-icon large color="blue"> mdi-check-circle-outline </v-icon>
              Área disponíveis
              {{ plano.numero_areas }}
            </p>

            <v-col width="100%" class="d-flex justify-center">
              <v-btn
                outlined
                class="title mx-1"
                color="blue accent-4"
                v-if="plano.id != $store.state.usuario.usuario.empresa.plano_id"
                @click="
                  $store.commit(`modal/SET_MODAL`, true),
                    $store.commit(`modal/SET_TYPE`, 3);
                  $store.commit(`plano/SET_NOVOPLANO`, plano);
                "
              >
                <v-icon> mdi-arrow-up-thick </v-icon>
                Fazer upgrade
              </v-btn>

              <v-btn
                outlined
                :disabled="!pago"
                class="mx-1 title"
                color="#00bdae"
                v-else
                @click="
                  $store.commit(`modal/SET_MODAL`, true),
                    $store.commit(`modal/SET_TYPE`, 2)
                "
              >
                <v-img
                  v-show="pago"
                  src="@/assets/pixIcone.png"
                  class="FazOPix"
                />
                <span v-if="pago"> Pagar Por Pix </span>
                <span v-else> Pagamento Realizado </span>
              </v-btn>
            </v-col>
            <div
              id="validaDoPlano"
              v-if="plano.id == $store.state.usuario.usuario.empresa.plano_id"
            >
              Validade:
              {{ validade }}
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import planos from "@/api/planos";
import empresas from "@/api/empresas";

export default {
  data() {
    return {
      tab: null,
      planos: [],
      validade: "0/0/0000",
      pago: false,
    };
  },

  mounted() {
    this.listarPlano();
    this.statusFinanceiro();
  },

  methods: {
    async listarPlano() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const resposta = await planos.listar();

        this.planos = resposta.data.data.sort(this.organizarPorTicket());

        const planoAtual = this.$store.state.usuario.usuario.empresa.plano_id;
        const planoSelecionado = this.planos.find(
          (element) => element.id === planoAtual
        );

        if (planoSelecionado) {
          this.$store.commit(`plano/SET_PLANO`, planoSelecionado);
        }
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async statusFinanceiro() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const resposta = await empresas.statusFinanceiro(
          this.$store.state.usuario.usuario.empresa.id
        );
        this.validade = resposta.data.fatura.vencimento;

        this.pago = resposta.data.fatura.data_pagamento == null;

        this.$store.commit(
          `plano/SET_TICKETS_USADO`,
          resposta.data.tickets_usados
        );
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
        this.planos.sort(this.organizarPorTicket());
      }
    },

    organizarPorTicket() {
      return function (elem1, elem2) {
        if (elem1.tickets < elem2.tickets) {
          return -1;
        } else if (elem1.tickets > elem2.tickets) {
          return 1;
        } else {
          return 0;
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tables {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.botoes {
  justify-content: space-around;
  display: flex;
}
.FazOPix {
  height: 26px;
  width: 26px;
  display: flex;
}
#validaDoPlano {
  float: left !important;
}
</style>
